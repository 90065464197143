import React from 'react'
import SEO from '../../Seo'
import './style.css'
import Logo from "../../Images/Bewusstsein e.V. - Logo without bg.png"


function Impressum() {
  return (
    <div id='impressPage'>
        <SEO title="Impressum & Disclaimer" description="Hier siehst du, wem genau die Seite gehört und den Disclaimer."  />

        <div id="impress">

            <h1 className="pageTitle">Impressum</h1>
            <h2>Angaben gemäß § 5 TMG</h2>
            <p>
                <span id='vereinsname'>Bewusstsein e.V. - Jena</span>  

                <strong>Vertreten durch:</strong> 
                Vorstandsvorsitzende - Frau Raya Qassas

                <strong>Postanschrift: </strong> 
                <span id='adress'>
                    Schillerstr. 5 <br />
                    07743 Jena, Deutschland
                </span>

                <strong>Kontakt: </strong> 
                <span id='impressContact'>
                    Email: <a href='mailto:kontakt@bewusstsein-ev.de'>kontakt@bewusstsein-ev.de</a>
                </span>

                <strong>Eingetragen am </strong> 
                <span id='eintrag'>
                    Amtsgericht Jena | VR 231732
                </span>

                <strong id='webMaster'>Webmaster, Hosting, Design: Maather Hamame</strong>
                </p>
                <img src={Logo} alt="" />

        </div>
        <div id="disclaimer">
            <h1 className="pageTitle">Disclaimer</h1>
                <h3>Haftung für Inhalte</h3>
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene 
                    Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
                    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, 
                    übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach 
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                </p>
                <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen 
                    nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche 
                    Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung 
                    möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                    umgehend entfernen.
                </p>
                <h3>Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen 
                    Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                    Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der 
                    Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche 
                    Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                </p>
                <p>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte 
                    einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige 
                    Links umgehend entfernen.
                </p>
                <h3>Urheberrecht</h3>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen 
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der 
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                </p>
                <p>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter 
                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine 
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden 
                    von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>
            
        </div>

    </div>
  )
}

export default Impressum