import React, {useEffect, useState} from 'react'
import Logo from '../Images/Logo - without BG.png'
import TextBox from './Components/TextBox'

function Footer() {

	const [pageNr, setPageNr] = useState(0);
	var loggedIn = localStorage.getItem("loggedInAs") != null;

	var currentDate = new Date()
	
  
  useEffect(()=> {
    window.scrollTo(0,0)
    document.getElementById("headerRightSide").classList.remove("dropMenuActive")
	if(window.location.hash !== "#/" || window.location.hash !== null || window.location.hash !== "")
		document.getElementById("header").style.display = "flex"

  },[pageNr])

	return (
    	<div id='footer' data-aos="fade-up"  data-aos-delay="300">
			{loggedIn ? <p id='loggedInInfo'>Angemeldet als <span>{localStorage.getItem("loggedInAs")}</span></p> : null}
    		<div id='socials'>
				<a id="youtube" href="/" style={{"--hover-bc": "#b00"}}><i className="fa-brands fa-youtube"></i></a>
				{/* <a id="twitter" href="/"  style={{"--hover-bc": "#1DA1F2"}}><i className="fa-brands fa-twitter"></i></a> */}
				<a id="instagram" style={{"--hover-bc": "#a0a"}} href="/"><i className="fa-brands fa-instagram"></i></a>
				<a id="facebook" href="https://www.facebook.com/p/Bewusstsein-E-V-Jena-100083435916004/" style={{"--hover-bc": "#4267B2"}}><i className="fa-brands fa-facebook"></i></a>
				<a id="mail" href="mailto:kontakt@bewusstsein-ev.de"><i className="fa-regular fa-envelope"></i></a>
			</div>  
			
			<div id='footerInline'>
				<div>
				<img src={Logo} id="footerImg" alt='Logo' />
				<p id='copyright'>Bewusstsein e.V. © {currentDate.getFullYear()} Alle Rechte vorbehalten.</p>
				</div>
				<hr />
					<div id='footerBottom'>
						<a href='/#/impressum' onClick={()=>setPageNr(Math.random())}>Impressum & Disclaimer</a>
						<span style={{color: "#fff", fontSize: "20px"}}>|</span>
						<a href='/#/datenschutz' onClick={()=>setPageNr(Math.random())}>Datenschutzerklärung</a>
					</div>
					<p id='iconsP'>Icons und Piktogramme von <a href="https://icons8.com">Icons8</a></p>
			</div>		

    </div>
  )
}

export default Footer