import React, { useEffect } from 'react'
import MeetingPic from './Meeting.jpg'
function Typewriter() {

    useEffect(() => {

    }, [])

    return (
        <div className="typeWriter" data-aos="fade-down">
            <img src={MeetingPic} loading="lazy" alt='' id="myVideo" />

            <div className="typewriter-container" data-aos="zoom-out" data-aos-delay="400">
                <div className="content">
                    <div className="content__container">
                        <p className="content__container__text">
                            Bewusstsein e.V. =
                        </p>

                        <ul className="content__container__list">
                            <li className="content__container__list__item">Innovation</li>
                            <li className="content__container__list__item">Zusammenarbeit</li>
                            <li className="content__container__list__item">Integration</li>
                            <li className="content__container__list__item">Aufklärung</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}




export default Typewriter