import React from 'react'
import files from './filesData'
import '../../stylesheets/downloads.scss'
import { useParams } from 'react-router-dom'
import pdfIcon from './icons/pdf-icon.png'
import SEO from '../../Seo'

function Downloads(props) {
    const {focusedElement} = useParams();

    if(focusedElement){
        setTimeout(()=> document.getElementById(focusedElement).scrollIntoView(), 300)
    } 

    return (
        <div id='formulare'>
            <SEO title="Downloads" description="Hier findest du alle Formulare, die du bei uns gebrauchen könntest."  />
            <h1 className='pageTitle' data-aos="fade-right" data-aos-delay="300">Downloads</h1>
            <p className='pageDescription' data-aos="fade-left" data-aos-delay="500">
                In unserem Verein gibt es ziemlich viele Ereignisse, Kurse, Events usw. und um
                daran teilzunehmen muss man immer die dafür gedachten Formulare ausfüllen und uns 
                per Post bzw. per Email schicken. Und damit Sie bei der Suche nach den Formularen nicht
                auf Schwierigkeiten Stoßen, haben wir diese Seite erstellt.
                Auf dieser Seite finden Sie alle verfügbaren Formulare, die Sie brauchen könnten.
                Diese können Sie ganz einfach mit einem Klick kostenlos herunterladen!
            </p>

            <div id='formsTable' data-aos="fade-left" data-aos-delay="700">
                    {
                        files.map(file => {
                            return (<div className={focusedElement === file.containerId ? 'fileContainer focusedForm' : 'fileContainer'} key={file.containerId} id={file.containerId}>
                                <div className='fileInfo'>
                                    <img src={file.fileType === "pdf" ? pdfIcon : pdfIcon} alt="" />
                                    <div><p className='fileName'>{file.fileName}</p>
                                    <p className='fileDescription'>{file.fileDescription}</p></div>
                                </div>
                                <p className='fileDate'>{file.fileAdded.getDate()}.{file.fileAdded.getMonth()}.{file.fileAdded.getFullYear()}</p>
                                <a href={file.filePath} target="_blank" download className='downloadBtn' rel="noreferrer"><i className="fa-solid fa-download"></i></a>
                    </div>)
                        })
                    }
            </div>
        </div>
  )
}

export default Downloads