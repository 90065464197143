import React from 'react'
import files from './filesData'
import '../../stylesheets/aktuelles.scss'
import { useParams } from 'react-router-dom'
import SEO from '../../Seo'
import erfurtImg from './thumbnails/Erfurt.jpg'
import ausstellungImg from './thumbnails/Ausstellung.jpg'

function Aktuelles(props) {
    const {focusedElement} = useParams();

    if(focusedElement){
        setTimeout(()=> document.getElementById(focusedElement).scrollIntoView(), 300)
    } 

    return (
        <div id='aktuelles'>
            <SEO title="Aktuelles" description="Hier findest du alle Neuigkeiten und Berichte zu Events, die bereits durchgeführt wurden."  />
            <h1 className='pageTitle' data-aos="fade-right" data-aos-delay="300">Aktuelles</h1>
            <p className='pageDescription' data-aos="fade-left" data-aos-delay="500">
                In unserem Verein haben wir immer Neuigkeiten - genau so wie Events, Veranstaltungen 
                oder Treffen, bei denen Sachen entschieden werden. Deshalb haben wir uns überlegt,
                dass es schwer sein könnte, immer am Laufenden zu bleiben und haben uns deshalb dazu 
                entschieden, diese Seite zu erstellen, auf der wir alle Neuigkeiten, Informationen und 
                Berichte zu Events, Treffen und Veranstaltungen posten werden, sodass jeder sehr einfach 
                und ohne Zugang zu sozialen Medien auch am Laufenden bleiben kann. Wichtig ist hierbei auch,
                dass diese einen zentralen Ort haben, sodass es gar nicht schwer ist, diese Neuigkeiten 
                aufzurufen. 
            </p>

            <div id='postsContainer' data-aos="fade-left" data-aos-delay="700">

                <div className='post'>
                    <img src={erfurtImg} alt="test" />
                    <div className='postTextSection'>
                        <a href='#/posts/ErfurtFahrt'>
                            <h2>Fahrt nach Erfurt</h2>
                        </a>
                        <p className="date">02.03.2023</p>
                        <p className="description">
                            Am 02.03.2023 hatten wir eine Exkursion nur für Frauen nach Erfurt vorbereitet. 
                            Wie es lief und was genau wir dort gemacht haben, erfahren Sie auf der Post-Seite.
                        </p>
                        <a className='postLink' href="#/posts/ErfurtFahrt">zum Post</a>
                    </div>
                </div>
                <div className='post'>
                    <img src={ausstellungImg} alt="test" />
                    <div className='postTextSection'>
                        <a href='#/posts/kulturelle_galerie'>
                            <h2>Kulturelle Austellung</h2>
                        </a>
                        <p className="description">
                            Anlässlich des Frauentags haben wir uns überlegt, etwas sehr besonderes und interkulturelles zu 
                            veranstalten, nämlich eine Ausstellung, bei der die Frauen handgemachte Sachen, die z.B. in der
                            Heimat besonders traditionell gemacht werden, anbieten bzw. verkaufen können. Weiteres erfahren Sie 
                            auf der Post-Seite.
                        </p>
                        <a className='postLink' href="#/posts/kulturelle_galerie">Zum Post</a>
                    </div>
                </div>
                                 
            </div>
        </div>
  )
}

export default Aktuelles