import React, { useEffect, useState } from 'react'
import logo from '../Images/Bewusstsein e.V. - Logo shorted.png'
import { auth } from '../Pages/intern/firebase';
import { signOut } from "@firebase/auth"
import './style.css'


export function logOut(e) {
  e.preventDefault();
  signOut(auth).then(() => {
    localStorage.removeItem("loggedInAs")
    window.location.reload()
  })
}

function Header() {

  var loggedIn = localStorage.getItem("loggedInAs") != null;

  const [pageNr, setPageNr] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.getElementById("headerRightSide").classList.remove("dropMenuActive")
    if(window.location.hash !== "#/" || window.location.hash !== null || window.location.hash !== "")
      document.getElementById("header").style.display = "flex"
  }, [pageNr])



  return (
    <div id='header' className='header' data-current_site="1">
      <a href='/' id='logoLink'>
        <img src={logo} alt='Logo' />
        <span>Bewusstsein e.V.</span>
      </a>
      <label id='toggleDropMenu' onClick={e => {
        if(document.getElementsByClassName("header")[1])
            document.getElementsByClassName("header")[1].children[2].classList.toggle("dropMenuActive")
          else
          document.getElementsByClassName("header")[0].children[2].classList.toggle("dropMenuActive")

        }}>
        <i className="fa-solid fa-bars"></i>
      </label>

      <div id='headerRightSide' className='dropMenu'>
        <a href='/#/' onClick={() => setPageNr(Math.random())}>Startseite</a>
        <a href='/#/about' onClick={() => setPageNr(Math.random())}>Über uns</a>
        <a href='/#/aktuelles' onClick={() => setPageNr(Math.random())}>Aktuelles</a>
        <a href='/#/downloads' onClick={() => setPageNr(Math.random())}>Downloads</a>
        <a href='/#/kontakt' onClick={() => setPageNr(Math.random())}>Kontakt</a>
        {loggedIn ? <a href="/" onClick={logOut} id="logOutBtn">Ausloggen</a> : null}
      </div>

    </div>
  )
}



export default Header