import '../../stylesheets/home.scss';
import Typewriter from './Typewriter';
import WomenIcon from "../icons/women.png"
import DolmetscherIcon from "../icons/dolmetscher.png"
import ComputerIcon from "../icons/ComputerIcon.svg"
import NachhilfeIcon from "../icons/NachhilfeIcon.png"
import { useEffect } from 'react';
import BewusstseinLogo from '../../Images/Bewusstsein e.V. - Logo shorted - colors.png'
import Header from '../../Layout/Header';
import SEO from '../../Seo';

function Home() {

	useEffect(() => {
		//document.getElementsByClassName("typewriter")[0].
		document.getElementsByClassName("header")[0].style.display = "none"
	}, [])

	return (
		<div id='home'>
			<SEO title="Bewusstsein e.V." description="Hier siehst du, wem genau die Seite gehört und den Disclaimer." />

			<Typewriter />
			<div >
			<Header />
			<div id="offersSection" >
				<h1 data-aos="fade-right" data-aos-delay="100" className='pageTitle'>Unsere Angebote:</h1>
				<p data-aos="fade-left" className='pageDescription' data-aos-delay="200">
					Unser Ziel ist es, bestimmte Gruppen, die es besonders schwer haben,
					sich in die deutsche Gesellschaft zu integrieren, die Integration einfacher 
					zu machen und Möglichkeiten anzubieten, die Fähigkeiten und Kenntnisse zu 
					fördern und zu verstärken. Im folgenden können Sie alle bis jetzt von uns 
					zur Verfügung gestellte Dienstleistungen und Kurse finden, an denen Sie auch ganz 
					einfach teilnehmen können!
				</p>

				<div id='offers' >

					<div className='offer' data-aos="fade-right" data-aos-delay="300">
						<img src={WomenIcon} alt='' />
						<h2>Deutschkurs für Frauen</h2>
						<p>
							Die Frau nimmt immer mehr Rollen in der Gesellschaft ein, allerdings 
							kann die Sprache eine große Barriere darstellen, die nur schwer zu 
							überwinden ist. Aus diesem Grund sahen wir einen Kurs für Frauen als 
							besonders wichtig, um so eine große Lücke zu schließen.
						</p>
						<a href='/#/Formulare/antrag_deutschkurs_frauen' className='btn'>Zum Formular</a>
					</div>
					
					<div className='offer' data-aos="fade-left" data-aos-delay="400">
						<img src={DolmetscherIcon} alt='' />
						<h2>Sprachmittler</h2>
						<p>
							Viele Personen haben immer noch Schwierigkeiten mit der Sprache und müssen
							trotzdem zu wichtigen Terminen o.ä. erscheinen und damit keine Missverständnisse 
							entstehen, ist es sehr wichtig, einen Dolmetscher immer mit dabei zu haben.
							Genau aus diesem Grund haben wir uns dazu entschieden, dieses Angebot zur 
							Verfügung zu stellen.							
						</p>
						<a href='/#/Formulare' className='btn'>Zum Formular</a>
					</div>
					
					<div className='offer'  data-aos="fade-right" data-aos-delay="300">
						<img src={ComputerIcon} alt='' />
						<h2>Computerkurse</h2>
						<p>
							Die modernen Technologien und besonders die Computer greifen immer mehr in unser
							alltägliches Leben ein und werden immer mehr in Arbeitsstellen benötigt. Aus diesem
							Grund ist es sehr wichtig, die Möglichkeit zu geben, diese Fähigkeiten mit Hilfe 
							unserer Kurse zu erlernen. 
						</p>
						<a href='/#/Formulare' className='btn'>Zum Formular</a>

					</div>
					
					<div className='offer'  data-aos="fade-left"  data-aos-delay="400">
						<img src={NachhilfeIcon} alt=''/>
						<h2>Nachhilfe</h2>
						<p>
							Alle geflüchteten Schüler hatten am Anfang sehr große Probleme und Schwierigkeiten mit 
							der Sprache, was dazu geführt hat, dass ihnen jetzt viel Unterrichtsstoff fehlt, was noch
							nachgearbeitet werden muss und aus diesem Grund möchten wir jedem die Möglichkeit geben,
							extra Unterrichtsstunden in Form von Nachhilfestunden zu kriegen. 
						</p>
						<a href='/#/Formulare' className='btn'>Zum Formular</a>
					</div>

				</div>

				<div id="followUs" data-aos="zoom-out" data-aos-delay="500">
					<h1 className='pageTitle'>Folgt uns auch auf:</h1>
						<a href="https://www.instagram.com/bewusstseinev/" style={{"--hover-bc": "#D000FF"}}><i className='fa-brands fa-instagram'></i> Instagram</a>
						{/* <a href="" style={{"--hover-bc": "#1DA1F2"}}><i className='fa-brands fa-twitter'></i> Twitter</a> */}
						<a href="" style={{"--hover-bc": "#FF0000"}}><i className='fa-brands fa-youtube'></i> Youtube</a>
						<a href="https://www.facebook.com/p/Bewusstsein-E-V-Jena-100083435916004/" style={{"--hover-bc": "#4267B2"}}><i className='fa-brands fa-facebook'></i> Facebook</a>
				</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
