import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description}) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />


            { /* Facebook tags */ }
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            { /* Twitter tags */ }
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />


</Helmet>
)
}