export var posts = [
    {
        id:"ErfurtFahrt",
        title: "Fahrt nach Erfurt",
        description: "Hier finden Sie einige Bilder von der Exkursion nach Erfurt:",
        images: [
            "/PostsImages/ErfurtImages/Erfurt1.jpg",
            "/PostsImages/ErfurtImages/Erfurt2.jpg",
            "/PostsImages/ErfurtImages/Erfurt3.jpg"
        ]
    },
    {
        id:"Deutschkurs_Frauen",
        title: "Deutschkurs für Frauen",
        description: "Wie Sie ja bereits wissen, führen wir einen Deutschkurs für Frauen durch und hier können Sie einige Bilder von dem letzten Tag des Kurses sehen!",
        images: [
            "/PostsImages/DeutschKurs - Letzter Tag/dk1.jpg",
            "/PostsImages/DeutschKurs - Letzter Tag/dk2.jpg",
            "/PostsImages/DeutschKurs - Letzter Tag/dk3.jpg",
            "/PostsImages/DeutschKurs - Letzter Tag/dk4.jpg",
            "/PostsImages/DeutschKurs - Letzter Tag/dk5.jpg",
            "/PostsImages/DeutschKurs - Letzter Tag/dk6.jpg",
            "/PostsImages/DeutschKurs - Letzter Tag/dk7.jpg",
            "/PostsImages/DeutschKurs - Letzter Tag/dk8.jpg"
        ]
    },
    {
        id:"kulturelle_galerie",
        title: "Kulturelle Galerie",
        description: " ",
        images: [
            "/PostsImages/Kulturelle Galerie/1.jpg",
            "/PostsImages/Kulturelle Galerie/2.jpg",
            "/PostsImages/Kulturelle Galerie/3.jpg",
            "/PostsImages/Kulturelle Galerie/4.jpg",
            "/PostsImages/Kulturelle Galerie/5.jpg",
            "/PostsImages/Kulturelle Galerie/6.jpg",
            "/PostsImages/Kulturelle Galerie/7.jpg",
            "/PostsImages/Kulturelle Galerie/8.jpg",
            "/PostsImages/Kulturelle Galerie/9.jpg",
            "/PostsImages/Kulturelle Galerie/10.jpg",
            "/PostsImages/Kulturelle Galerie/11.jpg",
            "/PostsImages/Kulturelle Galerie/12.jpg",
            "/PostsImages/Kulturelle Galerie/13.jpg",
            "/PostsImages/Kulturelle Galerie/14.jpg"
        ]
    }
]