import React from 'react'
import "./style.css"
import { posts } from './posts'
import { useParams } from 'react-router'

function PostTemplate() { 
    const {id} = useParams();


  return (
    <div id='PostTemplate'>
        {
            posts.map(post => {
                if(id === post.id){
                    return(
                        <div key={Math.random() *99999999}>
                            <h1 className='pageTitle' data-aos="fade-left">{post.title}</h1>
                            <p className="pageDescription" data-aos="fade-left" data-aos-delay="300">{post.description}</p>
                            <div className="ImageContainer">{post.images.map(img => <img key={Math.random() *99999999} src={img} data-aos="zoom-in" data-aos-delay="300" alt="" />)}</div>
                        </div>
                        )
                }
                else 
                    return null 
            })
        }
    </div>
  )
}

export default PostTemplate