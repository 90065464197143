import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "@firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBAI6NS4U7mRa0uuNAKFDBEn1CRhItEZ7E",
  authDomain: "bewusstsein-e-v.firebaseapp.com",
  projectId: "bewusstsein-e-v",
  storageBucket: "bewusstsein-e-v.appspot.com",
  messagingSenderId: "661849969309",
  appId: "1:661849969309:web:b21c514dbf5e48c52cba74",
  measurementId: "G-SKLX1SF7PY"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
const analytics = getAnalytics(app);