var files = [{
    fileType: "pdf",
    containerId: "antrag_deutschkurs_frauen",
    fileName: "Anmeldeformular Deutsch-Kurs für Frauen.pdf",
    fileDescription: "Das Formular, das Sie für die Anmeldung für den Deutsch-Kurs für Frauen brauchen.",
    fileAdded: new Date(2022, 9, 18),
    filePath: "./files/Anmeldeformulare - PDF/Anmeldeformular - Deutschkurs für Frauen.pdf"
},
{
    fileType: "pdf",
    containerId: "antrag_nachhilfe",
    fileName: "Antrag - Nachhilfe.pdf",
    fileDescription: "Antrag für Nachhilfe für Schüler zwischen der 1. und 11. Klasse in den Hauptfächern (Mathe, Deutsch, Englisch).",
    fileAdded: new Date(2022, 9, 18),
    filePath: "./files/Anmeldeformulare - PDF/Antrag - Nachhilfe.pdf"
},
{
    fileType: "pdf",
    containerId: "antrag_tanzkurs_mädchen",
    fileName: "Anmeldeformular - Tanzkurs für Mädchen.pdf",
    fileDescription: "Das Formular, das Sie für die Anmeldung Ihrer Tochter für den Tanz-Kurs brauchen. Die Anmeldung ist nur für Mädchen, die 7 - 12 Jahre alt sind.",
    fileAdded: new Date(2022, 9, 18),
    filePath: "./files/Anmeldeformulare - PDF/Anmeldeformular - Tanzkurs für Mädchen.pdf"
}
,
{
    fileType: "pdf",
    containerId: "anmeldeformular_sportkurs_frauen",
    fileName: "Anmeldeformular - Sportkurs für Frauen.pdf",
    fileDescription: "Das Formular, das Sie für die Anmeldung für den Sport-Kurs für Frauen brauchen. Die Anmeldung ist nur für erwachsene Frauen möglich.",
    fileAdded: new Date(2022, 10, 22),
    filePath: "./files/Anmeldeformulare - PDF/Anmeldeformular - Sport-Kurs.pdf"
}]

export default files