import React from 'react'
import MailImg from './MailSent.png'
function MailSent() {
  return (
    <div id='Contact'>
        <h1 className='pageTitle'>Mail gesendet!</h1>
        <p className='pageDescription'>
            Vielen Dank für Ihre Anfrage! 
            Ihre Anfrage wurde erfolgreich abgeschickt! Wir bitten Sie, etwas geduld zu haben, bis unsere Mitarbeiter 
            Ihr Anliegen bearbeitet haben.
        </p>
        <img id='mailSent' src={MailImg} alt="" />
        <a href="/" className='darkBtn'>Zur Startseite <img src="https://img.icons8.com/?size=100&id=jPDM9Pv61iP5&format=png&color=000000" alt="" /></a>
        </div>
  )
}

export default MailSent