import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import Home from './Pages/Home/Home';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import reportWebVitals from './reportWebVitals';
import { Route, Routes } from 'react-router';
import { HashRouter } from 'react-router-dom';
import Contact from './Pages/Kontakt/Kontakt.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './stylesheets/Main.scss'
import Downloads from './Pages/Formulare/Downloads.jsx';
import About from './Pages/About/About';
import Impressum from './Pages/Impressum/Impressum';
import Datenschutz from './Pages/Datenschutz/Datenschutz';
import MailSent from './Pages/Kontakt/MailSent';
// import InternRouter from './Pages/intern/InternRouter';
import Aktuelles from './Pages/Aktuelles/Aktuelles';
import PostTemplate from './Pages/Aktuelles/posts/PostTemplate';


const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};

const subdomains = window.location.host.split(".")


root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
    <Header />
    <div id='page'>
      <ToastContainer />
      
    {
    subdomains[0] !== "intern" ? 
    <HashRouter>
     <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/kontakt' element={<Contact />} />
        <Route exact path='/kontakt/sent' element={<MailSent />} />
        <Route exact path='/downloads' element={<Downloads />} />
        <Route exact path='/formulare' element={<Downloads />} />
        <Route exact path='/downloads/:focusedElement' element={<Downloads />} />
        <Route exact path='/formulare/:focusedElement' element={<Downloads />} />
        <Route exact path='/aktuelles' element={<Aktuelles />} />
        <Route exact path='/posts/:id' element={<PostTemplate />} />
        <Route exact path='/impressum' element={<Impressum />} />
        <Route exact path='/datenschutz' element={<Datenschutz />} />
      </Routes> 
      </HashRouter>
      : subdomains[0] === "intern" ?
        // <InternRouter/> 
        null
       : null}
      </div>
    <Footer/>
    </HelmetProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
