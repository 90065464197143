import React from 'react'
import WomenIcon from "../icons/women.png"
import DolmetscherIcon from "../icons/dolmetscher.png"
import ComputerIcon from "../icons/ComputerIcon.svg"
import NachhilfeIcon from "../icons/NachhilfeIcon.png"
import '../../stylesheets/about.scss'
import SEO from '../../Seo'
function About() {
	return (
			<div id='about'>
				<SEO title="Über uns" description="In dieser Seite erzählen wir mehr darüber, wer wir genau sind was was und warum wir alles anbieten."  />
 
				<h1 className='pageTitle' data-aos="fade-right" data-aos-delay="300">Über uns</h1>
				<p className='pageDescription' data-aos="fade-left" data-aos-delay="500">
					Grundsätzlich sind wir eine Gruppe, die sich dafür zusammengefunden hat, 
					die Menschengruppen, die es besonders schwer haben, zu helfen. Für die bestmögliche 
					Hilfe haben wir uns für einige Angebote entschieden, die aber in der Zukunft sicherlich
					noch weiter ausgebaut werden. Wir haben versucht, mit diesen Angeboten die fehlenden bzw. 
					die unvollständigen Bereiche, die für die bestmögliche Integration nötig sind, abzudecken.
					Und im Endeffekt hoffen wir nur, dass wir mit Gotteshilfe den meisten Menschen helfen können, 
					sodass sie sich am besten in die deutsche Gesellschaft integrieren können. 
				</p>

				<div id='currentOffers'>
					<h2 data-aos="fade-left" data-aos-delay="700">Jetzige Angebote:</h2>

					<div className="currentOffer" data-aos="fade-right" data-aos-delay="900">
						<div className='currentOfferTextSection'>
							<h2>Deutschkurs für Frauen</h2>
							<p>
								Vor allem ältere Frauen leiden unter den Schwierigkeiten beim Lernen der Sprache und 
								haben deshalb kaum bis gar keine Möglichkeiten, sich in die deutsche Gesellschaft zu
								integrerieren. Diese Schwierigkeiten kommen auch dadurch zustande, dass viele ältere 
								Frauen gar keine Schule besucht haben und nicht mal deutsches Alphabet lesen oder schreiben 
								können und genau deshalb werden wir unsere Kurse in Stufen einteilen, bei denen z.B. Frauen,
								die das deutsche Alphabet weder schreiben noch lesen können, erstmal das lesen und schreiben 
								des Alphabets lernen können. Dadurch, dass sie die Sprache lernen, können sie dann auch die
								Lücken schließen und sich besser in die deutsche Gesellschaft integrieren, was ja unser Ziel
								hinter dem Ganzen ist. Ganz wichtig ist aber auch, dass in diesen Kursen nicht nur die Sprache 
								beigebracht wird, sondern auch die Rechte und Pflichten in der deutschen Gesellschaft, sodass
								alle Ahnung haben, wie sie sich in bestimmten Situationen verhalten oder nicht verhalten müssen.
							</p>
						</div>
						<div>
							<img src={WomenIcon} alt="" />
							<a href='/#/Formulare/antrag_deutschkurs_frauen'>Zum Formular</a>
						</div>
					</div>
						
					<div className="currentOffer" data-aos="fade-left" data-aos-delay="300">	
						<div className='currentOfferTextSection'>
							<h2>Computerkurse für Anfänger</h2>
							<p>
								Die Computer bzw. die modernen Technlogien allgemein bestimmen immer mehr unser 
								Alltagsleben und wer sich heute mit Computern nicht gut genug auskennt, bekommt 
								viele Schwierigkeiten in der Gesellschaft und vor Allem bei der Jobsuche, denn
								erstens: Die Jobsuche an sich erfolgt zu fast 90% nur digital und zweitens: 
								Heutzutage werden auch in den meisten Jobs mindestens Grundkenntnisse in diesem
								Bereich gefordert. Aus diesem Grund fanden wir das als besonders wichtig, Kurse in
								diesem Bereich anzubieten, da diese eine sehr große Lücke schließen würden und 
								dadurch viele auch ältere Personen mehr Jobmöglichkeiten bekommen können. 
							</p>
						</div>
						<div>
							<img src={ComputerIcon} alt="" />
							<a href='/#/Formulare'>Zum Formular</a>
						</div>
					</div>
				
					<div className="currentOffer" data-aos="fade-right" data-aos-delay="300">
						<div className='currentOfferTextSection'>
							<h2>Sprachmittler</h2>
							<p>
								Viele Personen mit Migrationshintergründen haben immernoch Schwierigkeiten mit der Kommunikation 
								in der deutschen Sprache, allerdings kann es immer dazu kommen, dass diese Personen auch Termine 
								in Behörden oder mit deutsch-sprachigen Einrichtungen hat, was klar eine besondere Herausforderung 
								beim Verständnis der jeweils anderen Seite darstellt. Genau deshalb empfinden wir es auch als wichtig,
								Sprachmittler als Unterstützung anbieten zu können, da dies Verständnisprobleme löst und zu einer 
								besseren Kommunikation führt. 
							</p>
						</div>
						<div>
							<img src={DolmetscherIcon} alt="" />
							<a href='/#/Formulare'>Zum Formular</a>
						</div>
					</div>
		
					<div className="currentOffer" data-aos="fade-left" data-aos-delay="300">
						<div className='currentOfferTextSection'>
							<h2>Nachhilfe für nicht-deutsche Schüler</h2>
							<p>
								Vor allem ältere Frauen leiden unter den Schwierigkeiten beim Lernen der Sprache und 
								haben deshalb kaum bis gar keine Möglichkeiten, sich in die deutsche Gesellschaft zu
								integrerieren. Diese Schwierigkeiten kommen auch dadurch zustande, dass viele ältere 
								Frauen gar keine Schule besucht haben und nicht mal deutsches Alphabet lesen oder schreiben 
								können und genau deshalb werden wir unsere Kurse in Stufen einteilen, bei denen z.B. Frauen,
								die das deutsche Alphabet weder schreiben noch lesen können, erstmal das lesen und schreiben 
								des Alphabets lernen können. Dadurch, dass sie die Sprache lernen, können sie dann auch die
								Lücken schließen und sich besser in die deutsche Gesellschaft integrieren, was ja unser Ziel
								hinter dem Ganzen ist. Ganz wichtig ist aber auch, dass in diesen Kursen nicht nur die Sprache 
								beigebracht wird, sondern auch die Rechte und Pflichten in der deutschen Gesellschaft, sodass
								alle Ahnung haben, wie sie sich in bestimmten Situationen verhalten oder nicht verhalten müssen.
							</p>
						</div>
						<div>
							<img src={NachhilfeIcon} alt="" />
							<a href='/#/Formulare'>Zum Formular</a>
						</div>
					</div>
			</div>
			
			{/* TODO: 
				<div id="partnerSection">
					<h1 className="pageTitle">Unsere Partner</h1>
					<p className="pageDescription"></p>
					<div id="partners"></div>
				</div> 
			*/}
		</div>
	)
}

export default About