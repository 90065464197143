import React, { useRef } from 'react'
import '../../stylesheets/kontakt.scss'
import {toast, ToastContainer} from 'react-toastify'
import SEO from '../../Seo';


function Contact() {


	return ( 
		<div id='Contact'>
			<SEO title="Kontakt" description="Hier kannst du uns direkt eine Nachricht mit dem Kontaktformular senden!"  />


			<h1 className='pageTitle' >Kontakt</h1>
			<p className='pageDescription' data-aos="fade-right">
				Mit dem folgenden Kontakt-Formular können Sie direkt mit uns Kontakt 
				aufnehmen und daraufhin werden wir versuchen, Ihr Anliegen sehr schnell 
				wie möglich zu bearbeiten. Alternativ können Sie uns auch unter der Email-Adresse 
				<a href="mailto:kontakt@bewusstsein-ev.de" style={{marginInline: 5}}>kontakt@bewusstsein-ev.de</a> 
				erreichen.
			</p>
			<div id='formCon' data-aos="fade-left" data-aos-delay="300">
				<form action="./php/sendMail.php" method='POST' id="contactForm">
					<input type="text" className="contactFormTb" placeholder="Name..." name="from_name" required /><br />
 
					<input type="email" className="contactFormTb" placeholder="Email..." name="sender_email" required /><br />
									
					<input type="text" className="contactFormTb" placeholder="Betreff..." name="betreff" required /><br />
								
					<textarea placeholder="Ihre Nachricht..." className="textarea" name="message" required></textarea>
					<br />
					<p id='dsHinweis'>Durch abschicken der Angaben stimmen Sie zu, dass Ihre angegebenen Daten zur Bearbeitung Ihrer Anfrage gemäß unserer <a href="/#/datenschutz">Datenschutzerklärung</a> verwendet werden.</p>
					<button type="submit" id="sendMail"><span>Absenden</span> <img width="50" height="50" src="https://img.icons8.com/ios/200/send--v1.png" alt="send--v1"/></button>
				</form>
			</div>
		</div>
	)
}

export default Contact